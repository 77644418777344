// eslint-disable-next-line max-classes-per-file
import { ContactsTab } from '~/app/shared/enums/contacts-tab.enum';
import { DetailState } from '~/app/shared/enums/detail-state.enum';
import { ContactRequestUpdate } from '~/app/shared/types/contacts/contact-request-update.type';
import { Contact } from '~/app/shared/types/contacts/contact.type';
import { ErrorType } from '~/app/shared/types/error.type';

export class ChangeContactErrorAction {
    static readonly type = '[ContactsDetails] Change contact error';

    constructor(public error: ErrorType) { }
}

export class ChangeTabAction {
    static readonly type = '[ContactsDetails] Change tab';

    constructor(public tab: ContactsTab) { }
}

export class ChangeContactFieldAction {
    static readonly type = '[ContactsDetails] Change contact field';

    constructor(public key: string, public value: any) { }
}

export class ChangeContactFieldsAction {
    static readonly type = '[ContactsDetails] Change contact fields';

    constructor(public fieldsChanged: Partial<Contact>) { }
}

export class CreateContactAction {
    static readonly type = '[ContactsDetails] Create current contact';
}

export class CreateContactSuccessAction {
    static readonly type = '[ContactsDetails] Create current contact success';

    constructor(
        public contact: Contact,
    ) {}
}

export class UpdateContactAction {
    static readonly type = '[ContactsDetails] Update contact in DB';

    constructor(public fieldsChanged: Partial<ContactRequestUpdate>) { }
}

export class ChangeContactStateAction {
    static readonly type = '[ContactsDetails] Change contact state';

    constructor(public contactState: DetailState) { }
}

export class ResetContactAction {
    static readonly type = '[ContactsDetails] Reset';
}

export class GetContactAction {
    static readonly type = '[ContactsDetails] Get contact';

    constructor(public contactId: number) { }
}

export class UpdateFormIsValidAction {
    static readonly type = '[ContactsDetails] Update form is valid';

    constructor(public isValid: boolean) { }
}
